<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item :to="{ name: 'video' }">视频管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{infoForm._id ? '编辑商品' : '添加商品'}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                <el-button @click="goBackPage" icon="arrow-left">返回列表</el-button>
            </div>
        </div>
        <div class="content-main">
            <div class="form-table-box">
                <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
                   
                    <el-form-item label="视频名" prop="name">
                        <el-input v-model="infoForm.name"></el-input>
                    </el-form-item>

                    <el-form-item label="视频简介" prop="video_brief">
                        <el-input type="textarea" v-model="infoForm.video_brief" :rows="3"></el-input>
                        <div class="form-tip"></div>
                    </el-form-item>

                    <el-form-item label="视频缩略图" prop="video_pic_url" v-if="infoForm.video_pic_url"
                                  class="image-uploader-diy new-height">
                        <img v-if="infoForm.video_pic_url" :src="infoForm.video_pic_url" class="image-show">
                        <el-button class="dele-list-pic" type="primary" @click="delePicList">
                            <i class="fa fa-trash-o"></i>
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
            
                        </el-button>
                    </el-form-item>
                    
                    <el-form-item label="视频缩略图" prop="video_pic_url" v-if="!infoForm.video_pic_url">
                        <el-upload
                                name="file"
                                class="upload-demo"
                                :action="qiniuZone"
                                :on-success="handleUploadListSuccess"
                                :before-upload="getQiniuToken"
                                list-type="picture-card"
                                :data="picData"
                        >

                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>

                        </el-upload>
                    </el-form-item>

                    
                    <el-form-item label="标签分类" prop="tags">
                        <el-select  @change="selectChanged" placeholder="请选择分类标签" multiple v-model="infoForm.tags">
                            <!-- <el-option label="选择所有" value="all"></el-option> -->
                            <el-option v-for="item in videoCateArray" :label="item.name" :value="item.name" :key="item._id"></el-option>
                        </el-select>

                    </el-form-item>

                     <el-form-item label="排序" prop="sort_order">
                        <el-input-number :mini="1" :max="100" v-model="infoForm.sort_order"></el-input-number>
                    </el-form-item>

                    <el-form-item label=" ">
                        <el-switch active-text="显示" inactive-text="隐藏" active-value="1" inactive-value="0"
                                   v-model="infoForm.is_show"></el-switch>
                    </el-form-item>

                                       
                    <el-form-item label="腾讯视频vid" prop="tx_vid">
                        <div slot="tip" class="el-upload__tip">注意：新版本将不再支持腾讯视频</div>
                        <el-input v-model="infoForm.tx_vid"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="视频号ID" prop="finderUserName">
                        <el-input v-model="infoForm.finderUserName"></el-input>
                    </el-form-item>

                    <el-form-item label="播放视频ID" prop="feedId">
                        <el-input v-model="infoForm.feedId"></el-input>
                    </el-form-item>

                    <!-- <el-form-item label="上传视频" prop="video_url" v-if="!infoForm.video_url" >
                        <el-upload
                                name="file"
                                class="avatar-uploader el-upload--text"
                                :action="qiniuZone"
                                :on-success="handleVideoSuccess"
                                :before-upload="beforeUploadVideo"
                                :on-remove="videoRemove"
                                list-type="video"
                                :accept="'video/*'" 
                                :data="picData"
                        >
                        
                        <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-form-item>
                    
                    <el-form-item label="上传视频" prop="video_url"   v-if="infoForm.video_url">

                        <video v-if="infoForm.video_url !='' && videoFlag == false" :src="infoForm.video_url" style="width: 320px;height:320px" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
                        <el-button class="dele-list-pic" type="primary" @click="deleteVideo">
                            <i class="fa fa-trash-o"></i>
                        </el-button>
                    </el-form-item> -->

                    <el-form-item label="视频时长" prop="video_length">
                        <el-input  v-model="infoForm.video_length"></el-input>
                        <div slot="tip" class="el-upload__tip">格式：11:11</div>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                        <el-button @click="goBackPage">返回列表</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog v-model="dialogVisible" size="tiny">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/config/api';
    import $ from 'jquery'
    export default {
        data() {
            return {
                root: '',
                qiniuZone:'',
                picData: {
                    token: ''
                },
                url: '',
                videoFlag:false ,
                videoUploadPercent:0,
                dialogImageUrl: '',
                dialogVisible: false,
                options: [],
                cateOptions: [],
                uploaderHeader: {
                    'LuoShao-Token': localStorage.getItem('token') || '',
                },
                infoForm: {
                    name: "",
                    video_pic_url: '',
                    video_url:'',
                    video_brief: '',
                    video_length: '',
                    is_show: "1",
                    sort_order:1,
                    tags:[],
                    tag_ids:[],
                    tx_vid:'',
                    finderUserName:'',
                    feedId:''
                    // is_index: false,
                },
                select_index:0,
                infoRules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ],
                    video_brief: [
                        {required: true, message: '请输入简介', trigger: 'blur'},
                    ],
                    video_pic_url: [
                        {required: true, message: '请选择视频缩略图', trigger: 'blur'},
                    ],
                    finderUserName:[
                        {required: true, message: '请填写视频号ID', trigger: 'blur'},
                    ],
                    feedId:[
                        {required: true, message: '请填写目标视频ID', trigger: 'blur'},
                    ]

                    
                    
                },
                specDatas: [],
                specOptionsList: [],
                specValue:1,
                selectedSpec: '规格',
                is_has_spec: false,
		        videoCateArray:[],//页面初始化时全部数据
            }
        },
        
        watch:{
            'infoForm.tags':{
            handler: function(val,oldval){
                    let newindex =  val.indexOf('all'),oldindex =  oldval.indexOf('all');   //获取val和oldval里all的索引,如果没有则返回-1
                    if(newindex!=-1 && oldindex==-1 && val.length>1)                       //如果新的选择里有勾选了选择所有选择所有 则 只直线勾选所有整个选项
                        this.videoCateArray=['all'];
                    else if(newindex!=-1 && oldindex!=-1 && val.length>1)                 //如果操作前有勾选了选择所有且当前也选中了勾选所有且勾选数量大于1  则移除掉勾选所有
                        this.videoCateArray.splice(val.indexOf('all'),1)                    
                }
            }
        },
        methods: {
           
            selectChanged(value) {
                console.log(value)
            },
            getVideoCate()
            {
                this.axios.get('video/getVideoCategorysList').then((response) => {
                    if(response.data.code>0)
                    {
                        var catelist = response.data.data;
                        this.videoCateArray = catelist;
                    }
                })
            },
			showCloseBtn(index){
				this.$refs[`closeI${index}`][0].style.display = 'block'; //显示叉叉
			},
			hideCloseBtn(index){
				this.$refs[`closeI${index}`][0].style.display = 'none';  //叉叉消失
			},
            deleteVideo()
            {
                this.infoForm.video_url = "";
                this.infoForm.video_length = "";
            },
            showTime(val){
                if(val<60){
                    var sec = Math.floor(val % 60).toFixed(0); // 余秒
                    return "00:"+sec;
                }else{
                var min_total = Math.floor(val / 60); // 分钟
                var sec = Math.floor(val % 60).toFixed(0); // 余秒

                if(min_total<60){
                    return min_total + ":" + sec;
                }
                else{
                
                    var hour_total = Math.floor(min_total / 60); // 小时数
                    var min = Math.floor(min_total % 60); // 余分钟
                        return hour_total + ":" + min + ":" + sec;
                    }
                }
            },
            beforeUploadVideo(file){
                let url = URL.createObjectURL(file);　　

            　　let audioElement = new Audio(url);
                var that = this;
                audioElement.addEventListener('loadedmetadata', (_event) => {
                    let playTime = audioElement.duration;
                    that.infoForm.video_length=this.showTime(playTime);
                });

                const isLt10M = file.size / 1024 / 1024  < 201;
                if (['video/mp4', 'video/ogg'].indexOf(file.type) == -1) {
                    this.$message.error('请上传正确的视频格式');
                    return false;
                }
                if (!isLt10M) {
                    this.$message.error('上传视频大小不能超过200MB哦!');
                    return false;
                }
                this.getQiniuToken();
            },
            uploadVideoProcess(event, file, fileList){
                this.videoFlag = true;
                this.videoUploadPercent = parseFloat(file.percentage).toFixed(0);
            },
            handleVideoSuccess(res, file) {                               //获取上传图片地址
                let url = this.url;
                this.infoForm.video_url = url + res.key;
                this.videoFlag = false;
            },
            videoRemove(res, file) {                               //获取上传图片地址
                let url = this.url;

                this.videoFlag = false;
                this.infoForm.video_url ="";
            },
            
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            hasErrorAct(err) {
                console.log(err);
            },
            getQiniuToken() {
                let that = this
                this.axios.get('qiniu/getQiniuToken').then((response) => {
                    let resInfo = response.data.data;
                    //console.log(resInfo.token);
                    that.picData.token = resInfo.token;
                    that.url = resInfo.url;
                })
            },
            delePicList() {
                // 不删除服务器上的图片，上传新的自动替换旧的
                this.infoForm.video_pic_url = '';
            },
            beforeUpload() {
                // 显示loading动画
                this.getQiniuToken();
                this.quillUpdateImg = true
            },
            uploadError() {
                // loading动画消失
                this.quillUpdateImg = false
                this.$message.error('图片插入失败')
            },
            goBackPage() {
                this.$router.go(-1);
            },
            onSubmitInfo() {
                this.$refs['infoForm'].validate((valid) => {
                    if (valid) {
                        
                        var binfo ={
                            name:this.infoForm.name,
                            video_brief:this.infoForm.video_brief,
                            tags:this.infoForm.tags,
                            tag_ids:this.infoForm.tag_ids,
                            video_pic_url:this.infoForm.video_pic_url,//视频缩略图
                            video_url:this.infoForm.video_url,
                            is_show:this.infoForm.is_show,//是否显示
                            video_playtimes:0,
                            video_length:this.infoForm.video_length,
                            sort_order:this.infoForm.sort_order,
                            tx_vid:this.infoForm.tx_vid,
                            finderUserName:this.infoForm.finderUserName,
                            feedId:this.infoForm.feedId
                        }

                        console.log("baseinfo is ");
                        console.log(binfo);
                       //  return false;

                        if(!!this.infoForm._id)
                        {
                            this.axios.post('video/updateVideo',
                            {
                                videoinfo: binfo,
                                id:this.infoForm._id
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '更新失败'
                                })
                            }
                        })
                        }
                        else
                        {
                            this.axios.post('video/addVideo',
                            {
                                videoinfo: binfo
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '保存成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '保存失败'
                                })
                            }
                        })
                        }
                        
                    } else {
                        return false;
                    }
                });
            },
            handleUploadListSuccess(res) {
                let url = this.url;
                this.infoForm.video_pic_url = url + res.key;
                console.log(this.infoForm);
                console.log("图片已经回来了！！！");
                
            },
            
            getInfo() {
                if (!this.infoForm._id) {
                    return false
                }
                return;
                //加载商品详情
                let that = this
                this.axios.get('video/getVideoInfo', {
                    params: {
                        id: that.infoForm.id
                    }
                }).then((response) => {
                    let resInfo = response.data.data;
                    let goodsInfo = resInfo.info;
                    that.infoForm = goodsInfo;
                })
            },
            
        },
        components: {
        },
        computed: {
        },
        mounted() {
            if(!!this.$route.query.videoInfo)
            {
                this.infoForm = this.$route.query.videoInfo ;

                if(!!this.infoForm._id)
                {
                    if(this.infoForm.video_url)
                    {
                        this.videoFlag = false;
                    }
                }

                console.log(this.infoForm);
                //this.getInfo();
            }
            this.getVideoCate();//获取视频分类标签
            this.getQiniuToken();
            this.root = api.rootUrl;
            this.qiniuZone = api.qiniu;
        },
    }

</script>

<style scoped>
    /* .edit_container{ */
    /*.avatar-uploader .el-upload {*/
    /*display: none;*/
    /*}*/

    .close-i{
		position: absolute;
		top:-8px;
		right: -9px;
		display: none;
		cursor: pointer;
		font-size: 18px;
		background-color: white;
		border-radius: 10px;
	}
	.newtag{
		position: relative;
		cursor: text;
		overflow: visible;
		display: inline-block;
		padding: 0;	
	}
	.newtag-content{
		margin: 7px 15px; 
	}
    .video-wrap{
        width: 300px;
    }
    .dialog-header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .dialog-header .value {
        width: 150px;
        margin-right: 14px;
    }

    .input-wrap .el-input {
        width: 200px;
        float: left;
        margin: 0 20px 20px 0;
    }

    .input-wrap .el-input input {
        background-color: #fff !important;
        color: #233445 !important;
    }

    .specFormDialig .el-input {
        width: 150px;
        margin-right: 10px;
    }

    .el-select-class {
        width: 200px;
        margin-right: 20px;
    }

    .sepc-form {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .spec-form-wrap {
        margin-top: 0 !important;
    }

    .add-spec {
        margin-top: 10px;
    }

    .spec-form-wrap .header {
        display: flex;
        justify-content: flex-start;
    }

    .spec-form-wrap .header .l {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    /*.sepc-form div{*/
    /*margin-left: 0;*/
    /*}*/

    .float-right {
        float: right;
    }

    .sepc-form .el-input {
        width: 200px;
        margin-right: 20px;
    }

    .marginTop20 {
        margin-top: 20px;
    }

    .checkbox-wrap .checkbox-list {
        float: left;
        margin-right: 20px;
    }

    .upload_ad{
        display: none;
    }
    .upload_ad .el-upload--picture-card {
        display: none;
    }

    .ql-container {
        min-height: 200px;
        max-height: 400px;
        overflow-y: auto;
    }

    .image-uploader-diy {
        /*height: 200px;*/
        position: relative;
    }

    /*.dele-list-pic {*/
        /*position: absolute;*/
        /*left: 380px;*/
        /*top: 0px;*/
    /*}*/

    .image-uploader-diy .el-upload {
        border: 1px solid #d9d9d9;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .image-uploader-diy .el-upload:hover {
        border-color: #20a0ff;
    }

    .image-uploader-diy .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .image-uploader-diy .image-show {
        min-width: 105px;
        height: 105px;
        display: block;
    }

    .image-uploader-diy .new-image-uploader {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-show {
        width: 165px;
        height: 105px;
        display: block;
    }

    .item-url-image-fuzhu .el-input {
        width: 260px;
    }

    .hidden {
        display: none;
    }
</style>
